import React, { createContext, useState } from 'react';

class VoidContext implements LenguageContext {
  get language(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setLanguage(): never {
    throw new Error('Cannot consume context outside of provider');
  }
}

interface LenguageContext {
  language: {
    language: 'en' | 'es' | 'pt';
    id: number;
  };
  setLanguage: Function;
}

export const LenguageContext = createContext<LenguageContext>(
  new VoidContext()
);

export const LenguageContextProvider: React.FC = ({ children }) => {
  const [language, setLanguage] = useState<{
    language: 'en' | 'es' | 'pt';
    id: number;
  }>({
    language: 'es',
    id: 1,
  });

  return (
    <LenguageContext.Provider
      value={{
        language,
        setLanguage,
      }}
    >
      {children}
    </LenguageContext.Provider>
  );
};
