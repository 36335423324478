import React, { Suspense, useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import moment from 'moment';
import withClearCache from '../hocs/withClearCache';

import 'moment/locale/es';
import { LenguageContext } from '../contexts/LenguagesContext';

// Routes
const LandingPage = React.lazy(() => import('./LandingPage'));
const CortevaFlightPage = React.lazy(() => import('./CortevaFlightPage'));
const TermsPage = React.lazy(() => import('./TermsPage'));
const TermsPageBrevant = React.lazy(() => import('./TermsPageBrevant'));
const IndexPage = React.lazy(() => import('./IndexPage'));
const NotFoundPage = React.lazy(() => import('./NotFoundPage'));
const UserPage = React.lazy(() => import('./UserPage'));
const AuthPage = React.lazy(() => import('./AuthPage'));
const AuthPagePioneer = React.lazy(() => import('./AuthPagePioneer'));
const DashboardPage = React.lazy(() => import('./DashboardPage'));
const ReportSeederPlate = React.lazy(() => import('./ReportSeederPlate'));
const TermsAndConditions = React.lazy(() =>
  import('../components/TermsAndConditions')
);
const PioneerOverseedingCoverage = React.lazy(() =>
  import('./PioneerOverseedingCoveragePage')
);

export const MainRouter = withClearCache(() => {
  const {
    user: { loading, data },
    countryCode,
  } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (data?.style_brand) {
      const currentUrl = window.location.href.includes('analytics');
      if (
        (currentUrl && data.style_brand === 7) ||
        (currentUrl && data.style_brand === 1)
      ) {
        window.location.href = '/dashboard';
      }
    }
  }, [data?.style_brand]);

  moment.locale('es');

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {loading.isSetUserLoading ? (
        <div>Loading...</div>
      ) : (
        <Switch>
          {/* {countryCode && countryCode === '+54' ? (
            <Route path="/arg/home" component={LandingPage} />
          ) : (
            <Route path="/mx" render={() => <Redirect to="home" />} />
          )} */}
          <Route path="/arg/home" component={LandingPage} />
          <Route exact path="/corteva-flight" component={CortevaFlightPage} />
          <Route path="/terms" component={TermsPage} />
          <Route path="/terms-brevant" component={TermsPageBrevant} />
          <Route
            path="/corteva-flight/terminosycondiciones"
            component={TermsAndConditions}
          />
          <Route exact path="/" component={IndexPage} />
          <Route
            path="/home/map"
            component={() => <Redirect to="/dashboard" />}
          />
          <Route path="/user" component={UserPage} />
          <Route path="/auth" component={AuthPage} />
          <Route path="/pioneer/auth" component={AuthPagePioneer} />
          <Route path="/dashboard" component={DashboardPage} />
          <Route
            path="/pioneer/overseeding_coverage"
            component={PioneerOverseedingCoverage}
          />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      )}
    </Suspense>
  );
});
