import React, { useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { MainRouter } from './routes/index';
import * as serviceWorker from './serviceWorker';
import { UserContextProvider } from './contexts/UserContext';
import { FiltersContextProvider } from './contexts/FiltersContext';
import { SeasonAndPeriodContextProvider } from './contexts/SeasonAndPeriodContext';
import { MapProvider } from './contexts/MapContext';
import { EditFieldProvider } from './contexts/EditFieldContext';
import { TutorialProvider } from './contexts/TutorialContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { StylesAcordingToUserProvider } from './contexts/StylesAcordingToUserContext/StylesAcordingToUserContext';

import es from './i18n/locales/es.json';
import en from './i18n/locales/eng.json';
import pt from './i18n/locales/pt.json';

import './fonts.scss';
import './assets/styles/global.scss';
import { VariablesProvider } from './contexts/variablesContext';
import {
  LenguageContext,
  LenguageContextProvider,
} from './contexts/LenguagesContext';

if (process.env.REACT_APP_API_ENV && process.env.REACT_APP_API_ENV === 'dev') {
  TagManager.initialize({
    gtmId: 'GTM-K82H9PL',
  });

  ReactGA.initialize('UA-113911101-2');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

if (process.env.REACT_APP_API_ENV && process.env.REACT_APP_API_ENV === 'prod') {
  TagManager.initialize({
    gtmId: 'GTM-53HX52H',
  });
  ReactGA.initialize('UA-113911101-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  Sentry.init({
    dsn:
      'https://a712812d4f3042659c030aa63b507018@o465023.ingest.sentry.io/5476509',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  });
}

if (window.location.href.includes('milote.com.ar')) {
  window.location.assign(window.location.href.replace('.com.ar', '.io'));
}

if (window.location.href.includes('milote.com.mx')) {
  window.location.assign(window.location.href.replace('.com.mx', '.io'));
}

if (window.location.href.includes('3.219.220.146')) {
  window.location.assign(
    window.location.href.replace(
      '3.219.220.146',
      'dev.web.milote.dev-corteva-arg.com'
    )
  );
}

const App = () => {
  const { language, setLanguage } = useContext(LenguageContext);

  const messages = {
    es,
    en,
    pt,
  };

  // useEffect(() => {
  //   const userLanguage = navigator.language.split('-')[0];
  //   console.log(navigator.language);
  //   setLanguage(userLanguage === 'en' ? 'en' : 'es');
  // }, []);

  return (
    <IntlProvider
      locale={'es-AR'}
      // defaultLocale="es-AR"
      messages={messages[language.language]}
    >
      <Router>
        <UserContextProvider>
          <VariablesProvider>
            <EditFieldProvider>
              <MapProvider>
                <SeasonAndPeriodContextProvider>
                  <FiltersContextProvider>
                    <NotificationProvider>
                      <TutorialProvider>
                        <StylesAcordingToUserProvider>
                          <MainRouter />
                        </StylesAcordingToUserProvider>
                      </TutorialProvider>
                    </NotificationProvider>
                  </FiltersContextProvider>
                </SeasonAndPeriodContextProvider>
              </MapProvider>
            </EditFieldProvider>
          </VariablesProvider>
        </UserContextProvider>
      </Router>
    </IntlProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <LenguageContextProvider>
      <App />
    </LenguageContextProvider>
  </React.StrictMode>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// useEffect(() => {
//   const userLanguage = user.data?.selected_language_id//navigator.language.split('-')[0];
//   console.log(navigator.language);
//   switch(userLanguage){
//     case 1:
//       setLanguage({
//         language: 'es',
//         id: userLanguage
//       })
//     case 2:
//       setLanguage({
//         language: 'en',
//         id: userLanguage
//       })
//   }
// }, []);
